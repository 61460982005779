import {getWindDirection} from "../../../util/WindDirectionConvertor";

interface CurrentWindCardProps {
    windSpeed: number;
    windDirection: number
}

function CurrentWindCard(props: CurrentWindCardProps) {
    return (
        <div
            className="w-full h-64 lg:w-64 flex flex-col justify-between items-start bg-white bg-opacity-10 border-2 border-zinc-600 rounded-2xl animate-slowfade">

            <div className="flex flex-col">
                <p className="text-lg text-gray-500 px-8 pt-8 pb-2">Brzina vjetra</p>
                <h5 className="text-3xl font-bold text-gray-900 px-8">
                    {props.windSpeed} km/h
                </h5>
            </div>

            <div className="flex flex-col pb-8">
                <p className="text-lg text-gray-500 px-8 pb-2">Smjer</p>
                <h5 className="text-xl font-bold text-gray-900 px-8">
                    {getWindDirection(props.windDirection)}
                </h5>
            </div>

        </div>
    )
}

export default CurrentWindCard