import {Title} from "../components";
import React from "react";

function PrivacyPageEnglish() {
    return (
        <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 max-w-full flex flex-col z-10">
            <div className="w-full flex flex-col">
                <div className="flex flex-row justify-between items-center">
                    <Title title="Privacy Policy for RadioTG App"/>
                </div>

                <div className="w-full md:w-2/3 flex flex-col justify-center items-center md:items-start pt-20">

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        This Privacy Policy governs the manner in which RadioTG App collects, uses, maintains, and
                        discloses information collected from users (each, a "User") of the RadioTG App. This privacy
                        policy applies to the RadioTG App and all products and services offered by RadioTG.
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Personal
                        Information Collection
                    </h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        RadioTG App may collect personal identification information from Users in a variety of ways,
                        including, but not limited to, when Users visit our app, register on the app, fill out a form,
                        and in connection with other activities, services, features or resources we make available on
                        our app. Users may be asked for, as appropriate, name, email address, phone number. Users may,
                        however, visit our app anonymously. We will collect personal identification information from
                        Users only if they voluntarily submit such information to us.
                    </p>


                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Non-Personal
                        Information Collection
                    </h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        RadioTG App may collect non-personal identification information about Users whenever they
                        interact with our app. Non-personal identification information may include the browser name, the
                        type of computer and technical information about Users' means of connection to our app, such as
                        the operating system, the Internet service provider utilized, and other similar information.
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Firebase Analytics
                        and Crashlytics Data Collection
                    </h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        RadioTG App uses Firebase Analytics and Crashlytics to collect data about how Users interact
                        with our app, and to detect and diagnose app crashes. This data collection is used to improve
                        the app and to provide a better user experience.
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">GDPR Compliance
                    </h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        RadioTG App is GDPR compliant. We take the privacy and security of our Users' personal
                        information seriously, and we have implemented appropriate measures to ensure compliance with
                        the GDPR. We will not share any personal information with third parties without the User's
                        explicit consent.
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Data Retention
                    </h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        RadioTG App will retain Users' personal information for as long as necessary to provide the
                        services requested by the User or as required by law.
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Security
                    </h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        RadioTG App has implemented appropriate security measures to protect Users' personal information
                        from unauthorized access, alteration, disclosure, or destruction.
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Third-Party
                        Websites
                    </h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        Users may find advertising or other content on our app that links to the websites and services
                        of our partners, suppliers, advertisers, sponsors, licensors, and other third parties. We do not
                        control the content or links that appear on these websites and are not responsible for the
                        practices employed by websites linked to or from our app. In addition, these websites or
                        services, including their content and links, may be constantly changing. These websites and
                        services may have their own privacy policies and customer service policies. Browsing and
                        interaction on any other website, including websites which have a link to our app, is subject to
                        that website's own terms and policies.
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Changes to this
                        Privacy Policy
                    </h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        RadioTG App has the discretion to update this privacy policy at any time. When we do, we will
                        post a notification on the main page of our app. We encourage Users to frequently check this
                        page for any changes to stay informed about how we are helping to protect the personal
                        information we collect. You acknowledge and agree that it is your responsibility to review this
                        privacy policy periodically and become aware of modifications.
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Your Acceptance of
                        these Terms
                    </h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        By using this app, you signify your acceptance of this policy. If you do not agree to this
                        policy, please do not use our app. Your continued use of the app following the posting of
                        changes to this policy will be deemed your acceptance of those changes.

                        If you have any questions about this Privacy Policy, the practices of this app, or your dealings
                        with this app, please contact us at radiotomislavgrad@gmail.com.
                    </p>

                </div>

            </div>
        </div>
    )
}

export default PrivacyPageEnglish