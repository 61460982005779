import React from "react";
import ReactPlayer from "react-player";
import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { Title, PingLoading } from "../components";

function CameraPage() {
  const firestore = useFirestore();
  const cameraRef = doc(firestore, "liveCamera", "id");

  const { status, data } = useFirestoreDocData(cameraRef);

  if (status === "loading") {
    return (
      <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 xl:max-w-[500px] max-w-full flex flex-col z-10">
        <div className="w-full flex flex-col">
          <div className="flex flex-row justify-between items-center">
            <Title title="Kamera uživo" />
          </div>
          <PingLoading />
        </div>
      </div>
    );
  }

  return (
    <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 max-w-full flex flex-col z-10">
      <div className="w-full flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <Title title="Kamera uživo" />
        </div>

        <div className="w-full aspect-video mt-10 ">
          <ReactPlayer
            url={data.currentLink.toString()}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
}

export default CameraPage;
