import React, { MouseEventHandler } from "react";

interface StreamCardProps {
  id: string;
  title: string;
  description: string;
  img: string;
  onClick?: MouseEventHandler;
}

function StreamCard(props: StreamCardProps) {
  return (
    <div
      onClick={props.onClick}
      className="flex flex-row bg-white w-full h-22 my-6 shadow-md
            hover:shadow-lg hover:shadow-blue-100 active:shadow rounded-xl cursor-pointer transition ease-in-out duration-300 animate-slideright"
    >
      <img
        src={props.img}
        alt={props.title}
        className="rounded-l-xl w-24 h-24"
      />
      <div className="flex flex-col justify-center">
        <h3 className="pl-4 text-xl font-semibold text-gray-800 tracking-wider">
          {props.title}
        </h3>
        <p className="pl-4 text-sm text-sky-800 tracking-wider">
          {props.description}
        </p>
      </div>
    </div>
  );
}

export default StreamCard;
