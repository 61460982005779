import { Title } from "../components";
import React from "react";

import { phoneIcon, faxIcon, emailIcon, mapIcon } from "../assets";

import { ContactCard } from "../components";

function ContactPage() {
  return (
    <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 max-w-full flex flex-col z-10">
      <div className="w-full flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <Title title="Kontaktirajte nas" />
        </div>

        <div className="mt-10 pr-0 xl:pr-8 grid grid-cols-1 2xl:grid-cols-2 gap-8">
          <ContactCard
            title="Telefon"
            icon={phoneIcon}
            content={[
              "Redakcija: +387 34 352 909",
              "Marketing: +387 34 353 768",
            ]}
          />

          <ContactCard
            title="E-Mail"
            icon={emailIcon}
            content={[
              "radiotomislavgrad@gmail.com",
              "radio.tomislavgrad@tel.net.ba",
              "marketing@radiotg.com",
            ]}
          />

          <ContactCard
            title="Fax"
            icon={faxIcon}
            content={["+387 34 353 768"]}
          />

          <ContactCard
            title="Adresa"
            icon={mapIcon}
            content={["Trg dr. Franje Tuđmana 41", "80240, Tomislavgrad, BiH"]}
          />
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
