import {Title} from "../components";
import React from "react";

import {appImage} from "../assets"

function ApplicationPage() {

    return (
        <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 max-w-full flex flex-col z-10">
            <div className="w-full flex flex-col">
                <div className="flex flex-row justify-between items-center">
                    <Title title="Aplikacija"/>
                </div>

                <div
                    className="w-full mr-0 xl:mr-20 flex flex-1 flex-col 2xl:flex-row justify-center items-center pt-10">

                    <div className="flex flex-col py-8 pr-0 xl:pr-10">

                        <p className=" text-gray-800 pb-4 animate-slideleft">Slušajte RTG i na vasem pametnom telefonu
                            Preuzmite našu aplikaciju potpuno besplatno s Google play store-a. </p>


                        <p className=" text-gray-800 pb-10 animate-slideleft">Nadoknadite propuštene
                            emisije, slušajte RTG Music te još mnogo drugog sadržaja.</p>


                        <a href="https://play.google.com/store/apps/details?id=com.milictg.radiotg" target="_blank"
                           className="bg-blue-600 w-full xl:w-64 text-white py-4 px-8 flex flex-row justify-center
                           items-center rounded-2xl shadow-2xl hover:bg-blue-800 active:shadow transition ease-in-out duration-300 animate-slideleft">
                            Preuzmite aplikaciju
                        </a>

                    </div>

                    <img src={appImage} alt="aplikacija"
                         className="w-full object-contain md:w-64 xl:w-80 mx-0 xl:mx-32 p-8 2xl:ml-20 animate-slideright"/>

                </div>
            </div>
        </div>
    )
}

export default ApplicationPage