const Blob = () => {
    return (
        <div className="absolute h-96 z-0">
            <div className="absolute top-20 -left-10 w-96 h-96 bg-sky-200 rounded-full filter blur-xl opacity-60 animate-blob"></div>
            <div className="absolute top-10 right-20 w-96 h-96 bg-red-200 rounded-full filter blur-xl opacity-60 animate-blob animation-delay-2000"></div>
            <div className="absolute -bottom-52 -left-60 w-96 h-96 bg-amber-200 rounded-full filter blur-xl opacity-60 animate-blob animation-delay-4000"></div>
            <div className="absolute -bottom-80 left-30 w-96 h-96 bg-violet-200 rounded-full filter blur-xl opacity-60 animate-blob animation-delay-2000"></div>
            <div className="absolute -bottom-96 right-36 w-96 h-96 bg-lime-200 rounded-full filter blur-xl opacity-60 animate-blob animation-delay-1000"></div>
        </div>
    );
};

export default Blob;