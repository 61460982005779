import React from 'react';
import Modal from "react-modal";


interface AppInfoModalProps {
    isModalOpen: boolean,
    closeModal: React.MouseEventHandler;
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
};

function AppInfoModal(props: AppInfoModalProps) {
    const date = new Date();
    let year = date.getFullYear();

    return (
        <Modal
            isOpen={props.isModalOpen}
            onRequestClose={props.closeModal}
            style={customStyles}
            className="bg-slate-700 md:w-fit p-8 absolute flex flex-col items-center justify-center rounded-2xl z-50"
            overlayClassName="absolute top-0 left-0 w-full h-full bg-white bg-opacity-30 z-50"
            shouldCloseOnOverlayClick={true}
        >
            <h4 className="text-white text-center md:text-start text-2xl pb-4 font-semibold">Info o aplikaciji</h4>
            <h5 className="text-white text-center md:text-start text-xl pb-2">Radio Tomislavgrad web player</h5>
            <p className="text-white text-center md:text-start text-md pb-8">Verzija: 0.7.8-beta</p>
            <p  className="text-white text-center md:text-start text-md pb-2">Sva prava pridržana Radio Tomislavgrad {year}</p>

            <div className="flex flex-row">
                <p  className="text-white text-md pb-8">Dizajn i izrada:</p>
                <a
                    href="https://delminiusdevs.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-sky-500 hover:text-sky-200 transition ease-in-out duration-300 ml-2"
                >
                    DelminiusDevs
                </a>
            </div>

            <button onClick={props.closeModal} className="text-sky-500 hover:text-sky-200 transition ease-in-out duration-300 ml-2">
                Zatvori
            </button>

        </Modal>
    )
}

export default AppInfoModal

