import {Title} from "../components";
import React from "react";

function PrivacyPage() {
    return (
        <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 max-w-full flex flex-col z-10">
            <div className="w-full flex flex-col">
                <div className="flex flex-row justify-between items-center">
                    <Title title="Izjava o privatnosti za aplikaciju Radio TG"/>
                </div>

                <div className="w-full md:w-2/3 flex flex-col justify-center items-center md:items-start pt-20">

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        Ova izjava o privatnosti objašnjava na koji način aplikacija Radio TG (u daljnjem
                        tekstu: "aplikacija") prikuplja, koristi i otkriva podatke o korisnicima u skladu s Općom
                        uredbom o zaštiti podataka (GDPR).
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Prikupljanje
                        podataka</h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        Aplikacija koristi Firebase Analytics i Crashlytics kako bi prikupila podatke o upotrebi
                        aplikacije. Ovi podaci se koriste kako bi se poboljšala funkcionalnost aplikacije i pružila
                        bolja korisnička iskustva. Podaci koji se prikupljaju uključuju sljedeće:
                    </p>

                    <ul className="my-4 ml-8 list-disc">
                        <li className="text-gray-900 text-lg text-left">Informacije o uređaju (npr.
                            model, verzija operativnog sustava)
                        </li>
                        <li className="text-gray-900 text-lg text-left">Identifikator uređaja</li>
                        <li className="text-gray-900 text-lg text-left">Informacije o upotrebi aplikacije
                            (npr. koje su funkcije korištene i kako se aplikacija
                            koristi)
                        </li>
                        <li className="text-gray-900 text-lg text-left">Podaci o padovima aplikacije</li>
                    </ul>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        Ovi podaci se prikupljaju putem kolačića ili drugih sličnih tehnologija koje se nalaze na vašem
                        uređaju.
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Pravna osnova za
                        prikupljanje podataka</h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        Pravna osnova za prikupljanje podataka o upotrebi aplikacije je legitimni interes aplikacije za
                        poboljšanje funkcionalnosti aplikacije i pružanje boljeg korisničkog iskustva. Ovi podaci neće
                        se koristiti u druge svrhe osim navedenih u ovoj izjavi o privatnosti.
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Korištenje
                        podataka</h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        Prikupljeni podaci koriste se isključivo kako bi se poboljšala funkcionalnost aplikacije i
                        pružila bolja korisnička iskustva. Ti podaci se ne dijele s trećim stranama osim ako je to
                        potrebno radi zakonskih obveza ili kako bi se zaštitili interesi aplikacije.
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Sigurnost
                        podataka</h6>

                    <p className="text-gray-900 text-lg text-center md:text-left">
                        Aplikacija poduzima razumne mjere kako bi zaštitila prikupljene podatke od gubitka, krađe ili
                        neovlaštenog pristupa. To uključuje primjenu različitih sigurnosnih mjera i tehnologija za
                        zaštitu podataka.
                    </p>

                    <h6 className="text-gray-900 text-lg font-semibold text-center md:text-left py-8">Pohranjivanje
                        podataka</h6>

                    <p className="mb-20 text-gray-900 text-lg text-center md:text-left">
                        Prikupljeni podaci pohranjuju se na poslužiteljima treće strane koji se nalaze u Sjedinjenim
                        Državama. Ti podaci su dostupni samo određenim zaposlenicima aplikacije i određenim davateljima
                        usluga koji su ovlašteni za upotrebu ovih podataka. Svi podaci koji se prikupljaju i obrađuju
                        pohranjuju se i obrađuju u skladu s GDPR
                    </p>

                </div>

            </div>
        </div>
    )
}

export default PrivacyPage