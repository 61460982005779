import {Title} from "../components";
import React from "react";

import {aboutImage} from "../assets"

function AboutPage() {

    return (
        <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 max-w-full flex flex-col z-10">
            <div className="w-full flex flex-col">
                <div className="flex flex-row justify-between items-center">
                    <Title title="O nama"/>
                </div>

                <div className="w-full flex flex-1 flex-col xl:flex-row justify-center items-center pt-10">

                    <div className="flex flex-col py-8 pr-0 xl:pr-10">

                        <p className=" text-gray-800 pb-10 animate-slideleft">Radio Tomislavgrad osnovan je 18. svibnja
                            1992. godine, u vrijeme najžešćih ratnih operacija na kupreško – duvanjskoj bojišnici, što
                            znači da je utemeljen radi potrebe informiranja stanovništva u tim ratnim i kaotičnim
                            prilikama. Od početnih nekoliko sati, od 18. prosinca 1998. emitiramo 24 sata vlastitoga
                            programa.</p>

                        <p className=" text-gray-800 pb-10 animate-slideleft">Emitiramo s tri odašiljača, na
                            frekvencijama: 103.3, 95.9 i 95.1 MHZ. Zahvaljujući internetu imamo određeni broj slušatelja
                            u cijelome svijetu. Uglavnom su to naši ljudi koji žele čuti informacije iz rodnoga kraja, a
                            slušaju nas na adresi www.radiotg.com</p>

                        <p className=" text-gray-800 pb-10 animate-slideleft">Kroz sve ove godine našega djelovanja
                            uvijek smo nastojali biti u službi našim slušateljima. Tako je i danas. Zato, pronađite naš
                            signal i uvjerite se u kvalitetu i raznolikost programa koji svakodnevno nudimo. Vaš RTG</p>
                    </div>

                    <img src={aboutImage} alt="o nama" className="w-full xl:w-2/5 mx-0 xl:mx-10 animate-slideright rounded-2xl shadow-2xl"/>

                </div>
            </div>
        </div>
    )
}

export default AboutPage