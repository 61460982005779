import {useState, useEffect} from "react";

interface ProgressiveShowImageProps {
    placeholderImage: string,
    image: string,
    alt: string
}


function ProgressiveShowImage(props: ProgressiveShowImageProps) {

    const [imgSrc, setImgSrc] = useState(props.placeholderImage || props.image);

    useEffect(() => {
        const img = new Image();
        img.src = props.image;
        img.onload = () => {
            setImgSrc(props.image);
        };
    }, [props.image]);

    return (
        <img
            {...{src: imgSrc, ...props}}
            alt={props.alt || ""}
            className="rounded-2xl p-2 w-full object-cover aspect-square"
        />
    )

}

export default ProgressiveShowImage