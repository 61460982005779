import {useState, useEffect} from "react";

interface ProgressiveShowCardImageProps {
    placeholderimage: string,
    image: string,
    alt: string
}


function ProgressiveShowCardImage(props: ProgressiveShowCardImageProps) {

    const [imgSrc, setImgSrc] = useState(props.placeholderimage || props.image);

    useEffect(() => {
        const img = new Image();
        img.src = props.image;
        img.onload = () => {
            setImgSrc(props.image);
        };
    }, [props.image]);

    return (
        <img
            {...{src: imgSrc, ...props}}
            alt={props.alt || ""}
            className="rounded-t-2xl object-cover aspect-square"
        />
    )

}

export default ProgressiveShowCardImage