interface CurrentPressureCardProps {
    pressure: number;
}

function CurrentPressureCard(props: CurrentPressureCardProps) {
    return (
        <div
            className="w-full h-64 lg:w-64 flex flex-col justify-between items-start bg-white bg-opacity-10 border-2 border-zinc-600 rounded-2xl animate-slowfade">
            <p className="text-lg text-gray-500 p-8">Tlak zraka</p>
            <h3 className="text-4xl font-bold text-gray-900 p-8">
                {props.pressure}
                <span className="font-normal text-2xl text-gray-500"> hPa</span>
            </h3>
        </div>
    )
}

export default CurrentPressureCard