import React, {useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {AppInfoButton} from "../components"

import {
    RadioIcon,
    CloudIcon,
    ChartPieIcon,
    PhoneIcon,
    DocumentIcon,
    CameraIcon,
    DevicePhoneMobileIcon,
    BookmarkIcon,
    UserIcon,
    Bars3Icon,
    XMarkIcon,
    PencilIcon
} from "@heroicons/react/24/solid";

import {logo} from "../assets";

interface SidebarsProps{
    onInfoClicked: React.MouseEventHandler;
}

const navLinks = [
    {name: "Početna", to: "/", icon: RadioIcon},
    {name: "Vremenska prognoza", to: "/weather", icon: CloudIcon},
    {name: "Marketing", to: "/marketing", icon: ChartPieIcon},
    {name: "Kontakt", to: "/contact", icon: PhoneIcon},
    {name: "Dokumenti", to: "/documents", icon: DocumentIcon},
    {name: "Kamera uživo", to: "/camera", icon: CameraIcon},
    {name: "Aplikacija", to: "/app", icon: DevicePhoneMobileIcon},
    {name: "O nama", to: "/about", icon: UserIcon},
    {name: "Privatnost", to: "/privacy", icon: PencilIcon},
    {name: "RTG Portal", to: "/rtg_portal", icon: BookmarkIcon},
];

const NavLinks = ({handleClick, currentLink}: {
    handleClick: React.MouseEventHandler;
    currentLink: string
}) => (
    <div className="flex flex-col flex-1">
        {navLinks.map((item) => (
            <NavLink
                key={item.name}
                to={item.to}
                onClick={handleClick}
                className={`flex flex-row justify-start items-center mb-4 lg:mb-5 xl:mb-6 2xl:mb-8 text-sm font-medium
        text-gray-800 transition ease-in-out duration-300 hover:text-blue-800 animate-slideleft ${currentLink === item.to ? 'text-blue-800' : ``}`}
            >
                <item.icon className="w-6 h-6 mr-2"/>
                {item.name}
            </NavLink>
        ))}
    </div>
);

function Sidebar(props: SidebarsProps) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const currentLink = useLocation()

    return (
        <>
            <div className="bg-stone-transparent md:flex hidden sticky flex-col w-[240px] py-10 px-4 z-30 ">
                <img
                    src={logo}
                    alt="logo"
                    className="w-full h-30 object-contain p-4 animate-slowfade"
                />
                <NavLinks handleClick={() => {
                }} currentLink={currentLink.pathname}/>

                <AppInfoButton onClick={props.onInfoClicked}
                />

            </div>

            {/* Mobile sidebar */}
            <div className="absolute md:hidden block top-6 right-3 fixed z-50 bg-blue-200 rounded-3xl p-2 z-50">
                {!mobileMenuOpen ? (
                    <Bars3Icon
                        className="w-6 h-6 text-gray-800"
                        onClick={() => setMobileMenuOpen(true)}
                    />
                ) : (
                    <XMarkIcon
                        className="w-6 h-6 text-gray-800"
                        onClick={() => setMobileMenuOpen(false)}
                    />
                )}
            </div>

            <div
                className={`absolute top-0 h-screen w-2/3 bg-slate-50 z-50 p-6 md:hidden smooth-transition flex flex-col ${
                    mobileMenuOpen ? "left-0" : "-left-full"
                }`}
            >
                <img src={logo} alt="logo" className="w-full h-20 object-contain"/>
                <NavLinks handleClick={() => setMobileMenuOpen(false)} currentLink={currentLink.pathname}/>
                <AppInfoButton onClick={props.onInfoClicked}/>
            </div>
        </>
    );
}

export default Sidebar;
