import axios from "axios";
import React, {useEffect, useState} from "react";
import {PingLoading, Title, PortalCard} from "../components";
import {Link} from "react-router-dom"


function PortalPage() {

    const baseURL = "https://rtgportal.info/wp-json/wp/v2/posts?per_page=30";
    const [portalPosts, setPortalPosts] = useState<any[]>([])


    useEffect(() => {
        axios.get(baseURL).then((response) => {
            setPortalPosts(response.data);
        });
    }, [])

    if (!portalPosts) {
        return (
            <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 xl:max-w-[500px] max-w-full flex flex-col z-10">
                <div className="w-full flex flex-col">
                    <div className="flex flex-row justify-between items-center">
                        <Title title="RTG Portal"/>
                    </div>
                    <PingLoading/>
                </div>
            </div>
        );
    }


    return (

        <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 max-w-full flex flex-col z-10">
            <div className="w-full flex flex-col ">
                <div className="flex flex-row justify-between items-center">
                    <Title title="RTG Portal"/>
                </div>

                <div
                    className="mt-8 pr-0 xl:pr-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-8">
                    {portalPosts.map(
                        (post) => (
                            <Link
                                key={post.id}
                                to={{pathname: `${post.link}`}}
                                target="_blank"
                                className="w-full h-full"
                            >
                                <PortalCard
                                    title={post.title.rendered}
                                    image={post.better_featured_image.source_url}
                                />
                            </Link>
                        )
                    )}
                </div>
            </div>
        </div>

    )
}

export default PortalPage