import React, {useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import {getFirestore} from "firebase/firestore";
import {FirestoreProvider, useFirebaseApp} from "reactfire";
import {AudioPlayerProvider} from "react-use-audio-player";

import {
    HomePage,
    MarketingPage,
    ContactPage,
    DocumentsPage,
    CameraPage,
    ApplicationPage,
    AboutPage,
    ShowDetailPage,
    PortalPage,
    WeatherPage, PrivacyPage, PrivacyPageEnglish
} from "./pages";

import {Sidebar, MusicPlayer, Blob, AppInfoModal} from "./components";

function App() {
    const firestoreInstance = getFirestore(useFirebaseApp());

    const [playingStream, setPlayingStream] = useState({
        streamTitle: "",
        streamUrl: "",
        image: "",
        isStream: false,
    });

    const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)

    return (
        <FirestoreProvider sdk={firestoreInstance}>
            <AudioPlayerProvider>
                <div className="relative flex h-screen overflow-hidden scrollbar-hide bg-stone-50">
                    <Sidebar onInfoClicked={() => setIsInfoModalVisible(!isInfoModalVisible)}/>

                    <div className="flex-1 flex flex-col ">

                        <div
                            className="px-6 overflow-y-scroll hide-scrollbar flex xl:flex-row flex-col-reverse xl:pt-10 pt-20">
                            <div className="flex w-full h-fit pb-40 justify-center">
                                <Blob/>
                                <AppInfoModal
                                    isModalOpen={isInfoModalVisible}
                                    closeModal={() => setIsInfoModalVisible(false)}
                                />
                                <Routes>
                                    <Route path="/" element={<HomePage
                                        streamClicked={(streamUrl, streamImage, streamTitle) =>
                                            setPlayingStream(
                                                {
                                                    streamTitle: streamTitle,
                                                    streamUrl: streamUrl,
                                                    image: streamImage,
                                                    isStream: true
                                                }
                                            )
                                        }/>}/>
                                    <Route path="/weather" element={<WeatherPage/>}/>
                                    <Route path="/marketing" element={<MarketingPage/>}/>
                                    <Route path="/contact" element={<ContactPage/>}/>
                                    <Route path="/documents" element={<DocumentsPage/>}/>
                                    <Route path="/camera" element={<CameraPage/>}/>
                                    <Route path="/app" element={<ApplicationPage/>}/>
                                    <Route path="/about" element={<AboutPage/>}/>
                                    <Route path="/privacy" element={<PrivacyPage/>}/>
                                    <Route path="/privacy_english" element={<PrivacyPageEnglish/>}/>
                                    <Route path="/rtg_portal" element={<PortalPage/>}/>
                                    <Route
                                        path="/show-detail/:showId"
                                        element={
                                            <ShowDetailPage
                                                onShowClicked={(showUrl, showImage, showTitle) =>
                                                    setPlayingStream({
                                                        streamTitle: showTitle,
                                                        streamUrl: showUrl,
                                                        image: showImage,
                                                        isStream: false
                                                    })
                                                }
                                            />
                                        }
                                    />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </div>

                {playingStream?.streamUrl && (
                    <div
                        className="absolute h-32 bottom-0 left-0 right-0 flex animate-slowfade overflow-hidden scrollbar-hide
                            bg-gradient-to-br from-sky-50/30 to-white/30 backdrop-blur-lg z-40">
                        <MusicPlayer
                            streamTitle={playingStream.streamTitle}
                            streamUrl={playingStream.streamUrl}
                            streamImg={playingStream.image}
                            isStream={playingStream.isStream}
                            closePlayer={() =>
                                setPlayingStream({
                                    streamTitle: "",
                                    streamUrl: "",
                                    image: "",
                                    isStream: false
                                })
                            }
                        />
                    </div>
                )}


            </AudioPlayerProvider>
        </FirestoreProvider>
    );
}

export default App;
