import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import './index.css';
import App from './App';
import firebaseConfig from "./firebase/firebaseConfig";
import {FirebaseAppProvider} from 'reactfire';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <React.StrictMode>
            <Router>
                <App/>
            </Router>
        </React.StrictMode>
    </FirebaseAppProvider>
);

