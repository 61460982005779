interface WeatherPerDayCardProps {
    dayName: string,
    minTemp: number,
    maxTemp: number,
    narrative: string
}


function WeatherPerDayCard(props: WeatherPerDayCardProps) {
    return (
        <div
            className="w-full mb-8 flex flex-col justify-between items-start bg-white bg-opacity-10 border-2 border-zinc-600 rounded-2xl animate-slowfade">
            <h3 className="text-3xl font-bold text-gray-900 p-8">{props.dayName}</h3>
            <p className="text-md lg:text-2xl text-gray-700 px-8 pb-8">{props.narrative}</p>
            <p className="text-md lg:text-xl text-gray-500 px-8 pb-2">Minimalna temperatura: <span
                className="text-md lg:text-xl text-gray-800">{props.minTemp}</span></p>
            <p className="text-md lg:text-xl text-gray-500 px-8 pb-4">Maksimalna temperatura: <span
                className="text-md lg:text-xl text-gray-800">{props.maxTemp}</span></p>
        </div>)
}

export default WeatherPerDayCard