import React from "react";
import { collection, orderBy, query } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Title, PingLoading, DocumentCard } from "../components";

function DocumentsPage() {
  const firestore = useFirestore();
  const documentsCollection = collection(firestore, "documents");
  const documentQuery = query(documentsCollection, orderBy("date", "asc"));

  const { status, data: documents } = useFirestoreCollectionData(
    documentQuery,
    { idField: "id" }
  );

  console.log(documents);

  if (status === "loading") {
    return (
      <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 xl:max-w-[500px] max-w-full flex flex-col z-10">
        <div className="w-full flex flex-col">
          <div className="flex flex-row justify-between items-center">
            <Title title="Dokumenti" />
          </div>
          <PingLoading />
        </div>
      </div>
    );
  }

  return (
    <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 max-w-full flex flex-col z-10">
      <div className="w-full flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <Title title="Dokumenti" />
        </div>

        <div className="w-full flex flex-col items-start justify-center mt-20">
          {documents.map((document) => (
            <DocumentCard title={document.title} url={document.url} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default DocumentsPage;
