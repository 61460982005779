interface CurrentPerceptionCardProps {
    perceptionRate: number,
    perceptionTotal: number
}

function CurrentPerceptionCard(props: CurrentPerceptionCardProps) {
    return (
        <div
            className="w-full h-64 lg:w-64 flex flex-col justify-between items-start bg-white bg-opacity-10 border-2 border-zinc-600 rounded-2xl animate-slowfade">

            <div className="flex flex-col">
                <p className="text-lg text-gray-500 px-8 pt-8 pb-2">Trenutne oborine</p>
                <h5 className="text-3xl font-bold text-gray-900 px-8">
                    {`${props.perceptionRate} L ${"\u33A1"}/h`}
                </h5>
            </div>

            <div className="flex flex-col pb-8">
                <p className="text-lg text-gray-500 px-8 pb-2">Ukupne oborine</p>
                <h5 className="text-xl font-bold text-gray-900 px-8">
                    {`${props.perceptionTotal} L ${"\u33A1"}`}
                </h5>
            </div>

        </div>
    )
}

export default CurrentPerceptionCard