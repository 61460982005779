import React from "react";

interface MarketingSectionCardProps {
  title: string;
  descriptionOne: string;
  descriptionTwo: string;
  descriptionThree: string;
  image: string;
}

function MarketingSectionCard(props: MarketingSectionCardProps) {
  return (
    <div className="w-full flex flex-col 2xl:flex-row items-center justify-center pr-0 2xl:pr-40 pb-10 2xl:pb-20">
      <div className="flex flex-col py-8 pr-0 xl:pr-10">
        <h4 className="text-2xl font-bold py-10 text-gray-800 animate-slideleft">
          {props.title}
        </h4>
        <p className=" text-gray-800 pb-2 animate-slideleft">
          {props.descriptionOne}
        </p>
        <p className=" text-gray-800 pb-2 animate-slideleft">
          {props.descriptionTwo}
        </p>
        <p className=" text-gray-800 pb-2 animate-slideleft">
          {props.descriptionThree}
        </p>
      </div>
      <img
        src={props.image}
        alt="reklame"
        className="w-full md:w-1/2 2xl:w-2/5 mx-0 xl:mx-10 animate-slideright"
      />
    </div>
  );
}

export default MarketingSectionCard;
