import React, {useCallback, useEffect, useRef, useState} from "react";
import {useAudioPlayer, useAudioPosition} from "react-use-audio-player";
import Controls from "./Controls";
import {ProgressiveShowImage} from "../index";
import {placeholderImage} from "../../assets"

import {
    XMarkIcon
} from "@heroicons/react/24/solid";

interface MusicPlayerProps {
    streamTitle: string;
    streamUrl: string;
    streamImg: string;
    isStream: boolean;
    closePlayer: () => void;

}

const MusicPlayer = (props: MusicPlayerProps) => {
    const [barWidth, setBarWidth] = useState("0%")
    const seekBarElem = useRef<HTMLDivElement>(null)

    const {togglePlayPause, ready, loading, playing, stop, play, player} = useAudioPlayer({
        src: props.streamUrl,
        html5: true,
        format: ["mp3"],
        preload: 'metadata',
        autoplay: true,
        pool: 10,
        onplay: () => console.log("sound is playing!"),
        onend: () => {
            console.log("sound has ended!")
            restartStreamOnEnd()
        },
        onload: () => console.log("sound has loaded!"),
        onstop: soundId => console.log(`sound has stoped ${soundId}!`),
        onloaderror: (soundId, error) => console.log(`sound has load error ${error} ${soundId}!`),
        onplayerror: (soundId, error) => console.log(`sound has play error ${error} ${soundId}!`),
    });

    const {percentComplete, duration, position, seek} = useAudioPosition({
        highRefreshRate: true,
    });

    useEffect(() => {
        setBarWidth(`${percentComplete}%`)
    }, [percentComplete])


    const goTo = useCallback(
        (event: React.MouseEvent) => {
            if (props.isStream) return

            const {pageX: eventOffsetX} = event

            if (seekBarElem.current) {
                const elementOffsetX = seekBarElem.current.offsetLeft
                const elementWidth = seekBarElem.current.clientWidth
                const percent = (eventOffsetX - elementOffsetX) / elementWidth
                seek(percent * duration)
            }
        },
        [duration, playing, seek]
    )

    const restartStreamOnEnd = () => props.isStream ? restartPlayer() : stopAndClosePlayer()

    const restartPlayer = () => {
        stop()
        player?.unload()
        player?.load()
        play()
    }

    const stopAndClosePlayer = () => {
        stop()
        player?.unload()
        props.closePlayer()
    }

    const formatTime = (seconds: number) => {
        if (duration === Infinity) return "00:00"

        const floored = Math.floor(seconds)
        let from = 14
        let length = 5
        // Display hours only if necessary.
        if (floored >= 3600) {
            from = 11
            length = 8
        }

        return new Date(floored * 1000).toISOString().substr(from, length)
    }

    const elapsed = position


    if (!ready && !loading) return (
        <div className="relative sm:px-12 px-8 w-full flex items-center justify-between overflow-hidden scrollbar-hide">
            <div className="flex-1 flex flex-row items-center justify-center">
                <h3 className="animate-pulse text-md text-gray-800 font-semibold p-4">
                    Izvor za reprodukciju nije pronađen</h3>
            </div>
        </div>
    );

    if (loading) return (
        <div className="relative sm:px-12 px-8 w-full flex items-center justify-between overflow-hidden scrollbar-hide">
            <div className="flex-1 flex flex-row items-center justify-center">
                <h3 className="animate-ping text-md text-gray-800 font-semibold p-4">Učitavanje...</h3>
            </div>
        </div>
    );

    return (
        <div
            className="relative sm:px-2 px-8 w-full flex flex-row items-center justify-center md:justify-between overflow-hidden scrollbar-hide z-50">
            <div className="relative flex-1 flex flex-col md:flex-row items-center justify-center">

                <div className="flex flex-row items-center my-4 xl:my-0">
                    <div className="w-24 mx-8 hidden xl:block">
                        <ProgressiveShowImage
                            placeholderImage={placeholderImage}
                            image={props.streamImg}
                            alt={props.streamTitle}
                        />
                    </div>

                    <Controls isPlaying={playing} onClick={togglePlayPause}/>

                    <p className="text-sm text-gray-800 font-bold p-4">
                        {props.streamTitle}
                    </p>
                </div>

                <div className="flex flex-row items-center justify-center">
                    <div
                        className="text-gray-800 ml-0 xl:ml-20 w-16 mr-4 text-center overflow-hidden">{`${formatTime(elapsed)}`}
                    </div>

                    <div
                        className="cursor-pointer w-36 md:w-64 xl:w-96 h-4 bg-white overflow-hidden mx-2 rounded-3xl"
                        ref={seekBarElem}
                        onClick={goTo}
                    >
                        <div style={{width: barWidth}} className="h-full bg-sky-600"/>
                    </div>

                    <div className="text-gray-800 w-16 ml-4 text-center">{`${formatTime(duration)}`}</div>
                </div>

                <button onClick={stopAndClosePlayer} className="absolute right-8 top-2">
                    <XMarkIcon className="w-8 h-8 text-gray-800"/>
                </button>

            </div>
        </div>
    );
};

export default MusicPlayer;
