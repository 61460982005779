import React from "react";
import {collection, orderBy, query} from "firebase/firestore";
import {useFirestore, useFirestoreCollectionData} from "reactfire";
import {NavLink} from "react-router-dom";

import {ShowCard, Title, PingLoading, Streams} from "../components";

function HomePage(
    {
        streamClicked,
    }: {
        streamClicked: (
            streamUrl: string,
            streamImage: string,
            streamTitle: string
        ) => void;
    }
) {
    const firestore = useFirestore();
    const streamCollection = collection(firestore, "radioShow");
    const streamsQuery = query(streamCollection, orderBy("order", "asc"));

    const {status, data} = useFirestoreCollectionData(streamsQuery, {
        idField: "id",
    });

    if (status === "loading") {
        return (
            <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 xl:max-w-[500px] max-w-full flex flex-col z-10">
                <div className="w-full flex flex-col">
                    <div className="flex flex-row justify-between items-center">
                        <Title title="Naše emisije"/>
                    </div>
                    <PingLoading/>
                </div>
            </div>
        );
    }

    return (
        <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 max-w-full flex flex-col z-10">
            <div className="w-full flex flex-col">
                <div className="flex flex-col-reverse xl:flex-row justify-between items-start">

                    <div className="flex flex-col flex-1">
                        <Title title="Naše emisije"/>
                        <div
                            className="mt-8 pr-0 xl:pr-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-4 gap-8">
                            {data.map((showData) => (
                                <NavLink key={showData.id} to={`/show-detail/${showData.id}`}>
                                    <ShowCard
                                        id={showData.id}
                                        title={showData.title}
                                        description={showData.description}
                                        time={showData.time}
                                        img={showData.image}
                                    />
                                </NavLink>
                            ))}
                        </div>
                    </div>

                    <div className="xl:sticky relative w-full xl:w-96 top-0 h-fit pb-20">
                        <Streams
                            onStreamClicked={(streamUrl, streamImage, streamTitle) =>
                                streamClicked(streamUrl, streamImage, streamTitle)}
                        />
                    </div>

                </div>

            </div>
        </div>
    );
}

export default HomePage;
