import { ProgressiveShowCardImage } from "../../components";
import { placeholderImage } from "../../assets";

interface PortalCardProps {
    title: string,
    image: string
}

function PortalCard(props: PortalCardProps) {
    return (
        <div className="relative bg-white w-full h-full rounded-2xl shadow-md
            hover:shadow-lg hover:shadow-blue-100 active:shadow cursor-pointer transition ease-in-out duration-300 animate-slideup">

            <ProgressiveShowCardImage
                placeholderimage={placeholderImage}
                image={props.image ? props.image : placeholderImage}
                alt={props.title}
            />

            <p className="text-sm font-semibold my-4 mx-4 line-clamp-3">
                {props.title}
            </p>
        </div>
    )
}

export default PortalCard