import React from "react";

interface TitleProps {
    title: string
}

function Title(props: TitleProps) {

    return (
        <h2 className="text-2xl font-bold text-gray-800 tracking-widest">{props.title}</h2>
    )
}

export default Title