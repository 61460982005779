import {
    PlayIcon,
    PauseIcon,
} from "@heroicons/react/24/solid";
import {MouseEventHandler} from "react";

interface ControlsProps {
    isPlaying: boolean,
    onClick?: MouseEventHandler;
}

function Controls(props: ControlsProps) {
    return <div onClick={props.onClick} className="rounded-full bg-white flex h-12 w-12 items-center text-gray-800 justify-center
    cursor-pointer transition ease-in-out duration-300 hover:text-sky-600 hover:scale-[1.1] active:scale-[1]">
        {props.isPlaying ? <PauseIcon className="w-8 h-8"/> : <PlayIcon className="w-8 h-8"/>}
    </div>
}

export default Controls