import { documentIcon, downloadIcon } from "../../assets";

interface DocumentCardRef {
  title: string;
  url: string;
}

function DocumentCard(props: DocumentCardRef) {
  return (
    <div className="w-full flex flex-row px-4 py-6 mb-4 justify-start items-center border-2 border-zinc-600 rounded-2xl animate-slowfade">
      <img src={documentIcon} alt="dokument" className="w-10" />

      <h3 className="text-sm xl:text-md px-4">{props.title}</h3>

        <div className="flex flex-1 hidden xl:block"/>

      <a href={props.url} target="_blank">
        <img src={downloadIcon} alt="preuzmi" className="w-20 md:w-12 xl:w-10" />
      </a>
    </div>
  );
}

export default DocumentCard;
