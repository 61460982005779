import React from "react";
import {Title} from "../components";

import {marriedImage, adsImage} from "../assets"
import {MarketingSectionCard} from "../components"

function MarketingPage() {
    return (
        <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 max-w-full flex flex-col z-10">
            <div className="w-full flex flex-col">
                <div className="flex flex-row justify-between items-center">
                    <Title title="Marketing"/>
                </div>

                <div className="w-full flex flex-1 flex-col justify-center items-center pt-8">

                    <MarketingSectionCard
                        title="Reklamirajte svoj posao"
                        descriptionOne="Obično se kaže da, uz kvalitetu, proizvod prodaje i dobra reklama."
                        descriptionTwo=" To je općepoznata činjenica u poslovnom svijetu. Kreativnost i radiofoničnost glasova ljudi pred mikrofonom i
                                           osposobljenost naših tehničkih urednika, jamče dobar i uspješan marketinški proizvod."
                        descriptionThree="Kontaktirajte nas na telefon: +387 34 353 768"
                        image={adsImage}/>


                    <MarketingSectionCard
                        title="Prigodne čestitke"
                        descriptionOne="Lijepo je usrećiti nekoga u našem svakodnevnom životu."
                        descriptionTwo="Vjenčanja, godišnjice braka, rođendani, imendani,
                                        prigoda su da na neki način iznenadimo i razveselimo drage ljude.
                                        A najbolji način za to je uputiti im čestitku s najljepšim željama i
                                        prigodnim pjesmama putem valova Radija Tomislavgrad."
                        descriptionThree="Učinite to, nazovite nas, a ostalo je naša briga."
                        image={marriedImage}/>
                </div>


            </div>
        </div>
    )
}

export default MarketingPage