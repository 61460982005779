import {InformationCircleIcon} from "@heroicons/react/24/solid";
import React from "react";

interface AppInfoButtonProps{
    onClick: React.MouseEventHandler;
}

function AppInfoButton(props: AppInfoButtonProps) {
    return (
        <div
            onClick={props.onClick}
            className="w-full bg-zinc-700 hover:bg-zinc-900 shadow-lg hover:shadow:xl active:shadow
            rounded-full flex flex-row justify-start items-center py-2 transition ease-in-out duration-300 cursor-pointer animate-slideup z-20">
            <InformationCircleIcon
                className="w-6 h-6 mx-4 text-white"
            />
            <p className="text-white text-sm font-medium">App Info</p>
        </div>
    )
}

export default AppInfoButton