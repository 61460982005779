interface ContactCardProps {
  title: string;
  icon: string;
  content: string[];
}

function ContactCard(props: ContactCardProps) {
  return (
    <div className="w-full flex flex-col p-8 border-2 border-zinc-600 rounded-2xl animate-slowfade">
      <img src={props.icon} className="w-12" alt="kontakt" />

      <h3 className="text-2xl text-gray-900 py-4 font-semibold  animate-slideup">
        {props.title}
      </h3>

      {props.content.map((data) => (
        <p className="text-gray-600 animate-slideup">{data}</p>
      ))}
    </div>
  );
}

export default ContactCard;
