interface CurrentUvIndexCardProps {
    uvIndex: number;
}

function CurrentUvIndexCard(
    props: CurrentUvIndexCardProps
) {
    return (
        <div
            className="w-full h-64 lg:w-64 flex flex-col justify-between items-start bg-white bg-opacity-10 border-2 border-zinc-600 rounded-2xl animate-slowfade">
            <p className="text-lg text-gray-500 p-8">UV index</p>
            <h3 className="text-6xl font-bold text-gray-900 p-8">
                {props.uvIndex}
            </h3>
        </div>
    );
}

export default CurrentUvIndexCard;