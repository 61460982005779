import { ProgressiveShowCardImage } from "../../components";
import { placeholderImage } from "../../assets";

interface ShowCardProps {
  id: string;
  title: string;
  description: string;
  time: string;
  img: string;
}

function ShowCard(props: ShowCardProps) {
  return (
    <div
      className="relative bg-white w-full w-full rounded-2xl shadow-md
            hover:shadow-lg hover:shadow-blue-100 active:shadow cursor-pointer transition ease-in-out duration-300 animate-slideup"
    >
      <ProgressiveShowCardImage
        placeholderimage={placeholderImage}
        image={props.img ? props.img : placeholderImage}
        alt={props.title}
      />

      <h5 className="text-2xl font-semibold px-4 pt-4 truncate">
        {props.title}
      </h5>
      <p className="text-sm px-4 pb-2 text-sky-800 tracking-wider truncate">
        {props.time}
      </p>
    </div>
  );
}

export default ShowCard;
