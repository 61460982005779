import {CurrentFeelsLikeCard, CurrentUvIndexCard, PingLoading, Title} from "../components";
import React, {useEffect, useState} from "react";
import axios from "axios";

import {WeatherPerDay} from "../model/WeatherPerDay";
import {Summary, WeatherHistoryPerDay} from "../model/WeatherHistoryPerDay";
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
} from 'recharts';

import {
    CurrentTemperatureCard,
    CurrentHumidityCard,
    CurrentWindCard,
    CurrentPressureCard,
    CurrentPerceptionCard,
    WeatherPerDayCard,
} from "../components";
import {EpochToDay} from "../util/EpochToDay";

function WeatherPage() {
    const currentWeatherApi =
        "https://api.weather.com/v2/pws/observations/current?stationId=ITOMIS5&format=json&units=m&apiKey=4a5ef5c8f18044da9ef5c8f18054dac4";
    const [currentWeather, setCurrentWeather] = useState<any[]>([]);

    const weatherForecastApi =
        "https://api.weather.com/v3/wx/forecast/daily/5day?geocode=43.72,17.23&format=json&units=m&language=hr-HR&apiKey=4a5ef5c8f18044da9ef5c8f18054dac4";

    const weatherHistoryApi =
        "https://api.weather.com/v2/pws/dailysummary/7day?stationId=ITOMIS5&format=json&units=m&apiKey=4a5ef5c8f18044da9ef5c8f18054dac4"

    const [weatherForecast, setWeatherForecast] = useState()
    const [weatherForecastPerDay, setWeatherForecastPerDay] = useState<WeatherPerDay>()
    const [weatherHistoryPerDay, setWeatherHistoryPerDay] = useState<WeatherHistoryPerDay>()

    const [weatherHistoryTemperature, setWeatherHistoryTemperature] = useState(
        [
            {
                epoch: "",
                temperatura: 0
            }
        ]
    )

    const [weatherHistoryWindSpeed, setWeatherHistoryWindSpeed] = useState(
        [
            {
                epoch: "",
                brzina: 0
            }
        ]
    )

    const [weatherHistoryPerception, setWeatherHistoryPerception] = useState(
        [
            {
                epoch: "",
                padaline: 0
            }
        ]
    )

    useEffect(() => {
        axios.get(currentWeatherApi).then((response) => {
            setCurrentWeather(response.data.observations);
        });

        axios.get(weatherForecastApi).then((response) => {
            setWeatherForecast(response.data)
            setWeatherForecastPerDay(response.data)
        });

        axios.get(weatherHistoryApi).then((response) => {

            setWeatherHistoryTemperature(
                [
                    {
                        epoch: EpochToDay(response.data.summaries[0].epoch),
                        temperatura: response.data.summaries[0].metric.tempAvg
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[1].epoch),
                        temperatura: response.data.summaries[1].metric.tempAvg
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[2].epoch),
                        temperatura: response.data.summaries[2].metric.tempAvg
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[3].epoch),
                        temperatura: response.data.summaries[3].metric.tempAvg
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[4].epoch),
                        temperatura: response.data.summaries[4].metric.tempAvg
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[5].epoch),
                        temperatura: response.data.summaries[5].metric.tempAvg
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[6].epoch),
                        temperatura: response.data.summaries[6].metric.tempAvg
                    }
                ]
            )

            setWeatherHistoryWindSpeed(
                [
                    {
                        epoch: EpochToDay(response.data.summaries[0].epoch),
                        brzina: response.data.summaries[0].metric.windspeedHigh
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[1].epoch),
                        brzina: response.data.summaries[1].metric.windspeedHigh
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[2].epoch),
                        brzina: response.data.summaries[2].metric.windspeedHigh
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[3].epoch),
                        brzina: response.data.summaries[3].metric.windspeedHigh
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[4].epoch),
                        brzina: response.data.summaries[4].metric.windspeedHigh
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[5].epoch),
                        brzina: response.data.summaries[5].metric.windspeedHigh
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[6].epoch),
                        brzina: response.data.summaries[6].metric.windspeedHigh
                    }
                ]
            )

            setWeatherHistoryPerception(
                [
                    {
                        epoch: EpochToDay(response.data.summaries[0].epoch),
                        padaline: response.data.summaries[0].metric.precipTotal
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[1].epoch),
                        padaline: response.data.summaries[1].metric.precipTotal
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[2].epoch),
                        padaline: response.data.summaries[2].metric.precipTotal
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[3].epoch),
                        padaline: response.data.summaries[3].metric.precipTotal
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[4].epoch),
                        padaline: response.data.summaries[4].metric.precipTotal
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[5].epoch),
                        padaline: response.data.summaries[5].metric.precipTotal
                    },
                    {
                        epoch: EpochToDay(response.data.summaries[6].epoch),
                        padaline: response.data.summaries[6].metric.precipTotal
                    }
                ]
            )
        });
    }, []);

    if (!weatherForecast) {
        return (
            <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 xl:max-w-[500px] max-w-full flex flex-col z-10">
                <div className="w-full flex flex-col">
                    <div className="flex flex-row justify-between items-center">
                        <Title title="RTG Portal"/>
                    </div>
                    <PingLoading/>
                </div>
            </div>
        );
    }

    return (
        <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 max-w-full flex flex-col z-10">
            <div className="w-full flex flex-col">
                <div className="flex flex-row justify-between items-center">
                    <Title title="Trenutno u Tomislavgradu"/>
                </div>

                <div className="w-full mt-10">
                    {currentWeather.map((data) => (
                        <div key={0}
                             className="grid justify-items-start grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-2 xl:gap-4 2xl:gap-6 ">
                            <CurrentTemperatureCard key={1} temperature={data.metric.temp}/>
                            <CurrentFeelsLikeCard key={2} temperature={data.metric.windChill}/>
                            <CurrentHumidityCard key={3} humidity={data.humidity}/>
                            <CurrentWindCard key={4} windSpeed={data.metric.windSpeed} windDirection={data.winddir}/>
                            <CurrentUvIndexCard key={5} uvIndex={data.uv}/>
                            <CurrentPressureCard key={6} pressure={data.metric.pressure}/>
                            <CurrentPerceptionCard key={7}
                                                   perceptionRate={data.metric.precipRate}
                                                   perceptionTotal={data.metric.precipTotal}/>
                        </div>
                    ))}
                </div>


                <div className="flex flex-row justify-between items-center mt-20 animate-slowfade">
                    <Title title="Prosječna temperatura posljednih sedam dana"/>
                </div>

                <div className="w-full h-96 my-8">

                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={weatherHistoryTemperature}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="epoch"/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend/>
                            <ReferenceLine y={0} stroke="#000"/>
                            <Bar dataKey="temperatura" fill="#d97706"/>
                        </BarChart>
                    </ResponsiveContainer>

                </div>


                <div className="flex flex-row justify-between items-center mt-20 animate-slowfade">
                    <Title title="Prosječna brzina vjetra posljednih sedam dana"/>
                </div>

                <div className="w-full h-96 my-8">

                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={weatherHistoryWindSpeed}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="epoch"/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend/>
                            <ReferenceLine y={0} stroke="#000"/>
                            <Bar dataKey="brzina" fill="#0284c7"/>
                        </BarChart>
                    </ResponsiveContainer>

                </div>

                <div className="flex flex-row justify-between items-center mt-20 animate-slowfade">
                    <Title title={`Ukupna količina padalina posljednih sedam dana - litre po ${"\u33A1"} `}/>
                </div>

                <div className="w-full h-96 my-8">

                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            width={500}
                            height={300}
                            data={weatherHistoryPerception}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="epoch"/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend/>
                            <ReferenceLine y={0} stroke="#000"/>
                            <Bar dataKey="padaline" fill="#475569"/>
                        </BarChart>
                    </ResponsiveContainer>

                </div>


                <div className="flex flex-row justify-between items-center mt-10 animate-slowfade">
                    <Title title="Vrijeme sljedećih pet dana"/>
                </div>

                <div className="w-full 2xl:w-2/3 mt-10 pr-0 xl:pr-52 2xl:pr-44 flex flex-col ">

                    {weatherForecastPerDay?.dayOfWeek.map((data, index) => (
                        <WeatherPerDayCard
                            dayName={data}
                            minTemp={weatherForecastPerDay?.temperatureMin[index]}
                            maxTemp={weatherForecastPerDay?.temperatureMax[index]}
                            narrative={weatherForecastPerDay?.narrative[index]}/>
                    ))
                    }

                </div>

            </div>
        </div>
    );
}

export default WeatherPage;
