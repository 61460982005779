import { placeholderImage } from "../../assets";
import { ProgressiveShowImage } from "../../components";
import { MouseEventHandler } from "react";

interface ShowDetailProps {
  id: string;
  title: string;
  date: string;
  image: string;
  showUrl: string;
  onClick?: MouseEventHandler;
}

function ShowDetailCard(props: ShowDetailProps) {
  return (
    <div
      onClick={props.onClick}
      className="bg-white min-h-[300] flex flex-col justify-between items-center w-full w-full rounded-2xl shadow-md
            hover:shadow-lg hover:shadow-blue-100 active:shadow cursor-pointer transition ease-in-out duration-300 animate-slideup"
    >
      <div className="flex flex-col items-center">
        <ProgressiveShowImage
          placeholderImage={placeholderImage}
          image={props.image ? props.image : placeholderImage}
          alt={props.title}
        />
        <h6 className="text-xl font-semibold px-4 pt-1 text-center line-clamp-2">
          {props.title}
        </h6>
      </div>

      <div className="flex flex-col items-center">
        <p className="text-sm px-4 mt-4 text-gray-800 tracking-wider truncate">
          Emitirano:
        </p>
        <p className="text-sm px-4 pb-2 text-sky-800 tracking-wider truncate">
          {props.date}
        </p>
      </div>
    </div>
  );
}

export default ShowDetailCard;
