export function getWindDirection(degrees: number): string {
  const directions = [
    "Sjeverni",
    "Sjeveroistočni",
    "Istočni",
    "Jugoistočni",
    "Južni",
    "Jugozapadni",
    "Zapadni",
    "Sjeverozapadni",
  ];

  // Split into the 8 directions
  degrees = (degrees * 8) / 360;

  // round to the nearest integer.
  degrees = Math.round(degrees);

  // Ensure it's within 0-7
  degrees = (degrees + 8) % 8;

  return directions[degrees];
}
