import React from "react";
import { useParams } from "react-router-dom";
import { collection, orderBy, query } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { ShowDetailCard, Title, PingLoading } from "../components";

function ShowDetailPage({
  onShowClicked,
}: {
  onShowClicked: (
    streamUrl: string,
    streamimage: string,
    streamTitle: string
  ) => void;
}) {
  let params = useParams();

  const firestore = useFirestore();
  const streamCollection = collection(
    firestore,
    `radioShow/${params.showId}/showEntity`
  );
  const streamsQuery = query(streamCollection, orderBy("stamp", "desc"));

  const { status, data } = useFirestoreCollectionData(streamsQuery, {
    idField: "id",
  });

  if (status === "loading") {
    return (
      <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 xl:max-w-[500px] max-w-full flex flex-col z-10">
        <div className="w-full flex flex-col">
          <div className="flex flex-row justify-between items-center">
            <Title title="Emitirane emisije" />
          </div>
          <PingLoading />
        </div>
      </div>
    );
  }

  return (
    <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 max-w-full flex flex-col z-10 overflow-hidden scrollbar-hide">
      <div className="w-full flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <Title title="Emitirane emisije" />
        </div>
        <div className="mt-8 pr-0 xl:pr-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-8">
          {data.map((showData) => (
            <ShowDetailCard
              id={showData.id}
              title={showData.title}
              date={showData.date}
              image={showData.image}
              showUrl={showData.showUrl}
              onClick={() =>
                onShowClicked(showData.showUrl, showData.image, showData.title)
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default ShowDetailPage;
