import React from "react";
import {collection, orderBy, query} from "firebase/firestore";
import {useFirestore, useFirestoreCollectionData} from "reactfire";

import {StreamCard, Title} from "../components";

function Streams({onStreamClicked}: { onStreamClicked: (streamUrl: string, streamImage: string, streamTitle: string) => void }) {
    const firestore = useFirestore();
    const streamCollection = collection(firestore, "streams");
    const streamsQuery = query(streamCollection, orderBy("order", "asc"));

    const {status, data} = useFirestoreCollectionData(streamsQuery, {
        idField: "id",
    });

    if (status === "loading") {
        return (
            <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 xl:max-w-[500px] max-w-full flex flex-col">
                <div className="w-full flex flex-col">
                    <div className="flex flex-row justify-between items-center">
                        <Title title="Naši programi"/>
                    </div>
                    <div>
                        <p>Loading</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="xl:ml-6 ml-0 xl:mb-0 mb-6 flex-1 xl:max-w-[500px] max-w-full flex flex-col">
            <div className="w-full flex flex-col">
                <div className="flex flex-row justify-between items-center">
                    <Title title="Naši programi"/>
                </div>
                <div>
                    {data.map((cardData) => (
                        <StreamCard
                            key={cardData.id}
                            id={cardData.id}
                            title={cardData.title}
                            description={cardData.description}
                            img={cardData.image}
                            onClick={() => onStreamClicked(
                                cardData.url,
                                cardData.image,
                                cardData.title
                            )}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Streams;
